import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ReactECharts from "echarts-for-react";

function formatNumberToK(num) {
  return num > 999 ? (num / 1000).toFixed(1) + "K" : num.toString();
}

function ChartPie() {
  const [chartData, setChartData] = useState([]);
  const [totalHolders, setTotalHolders] = useState("");
  const [option, setOption] = useState({}); // State to manage chart options

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/holdingDistribution`
        );
        const distributionData = response.data.data.distribution.map((item) => ({
          name: item.name === "101" ? ">100" : item.name,
          value: item.value,
          proportion: item.proportion,
        }));
        const total = formatNumberToK(response.data.data.total);

        setChartData(distributionData);
        setTotalHolders(total);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const updateOption = () => {
      setOption({
        graphic: {
          type: "text",
          left: "center", // Position horizontally in the middle
          top: "center", // Position vertically in the middle
          style: {
            text: [
              "{total|" + totalHolders + "}", // Use the 'total' style
              "{holders|Holders}", // Use the 'holders' style
            ].join("\n"), // Join with a newline character
            rich: {
              total: {
                // Style for the 'total' part
                textAlign: "center",
                fill: "#fff", // Text color for totalHolders
                fontSize: 24,
                fontWeight: "bold",
                lineHeight: 40, // Adjust line height for better spacing
              },
              holders: {
                // Style for the 'holders' part
                textAlign: "center",
                fill: "#A0AEC0", // Text color for "Holders"
                fontSize: 16, // Smaller font size for "Holders"
                fontWeight: "normal", // Non-bold for "Holders"
              },
            },
            textAlign: "center",
            verticalAlign: "middle",
          },
        },
        tooltip: {
          trigger: "item",
          // Custom formatter for tooltip
          formatter: (params) => {
            // params is an object containing all the data related to the hovered item, such as name, value, percentage, etc.
            return `${params.name}<br/>${params.percent.toFixed(2)}%`;
          },
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Custom background color for the tooltip
          borderColor: "#333", // Custom border color for the tooltip
          borderWidth: 0,
          textStyle: {
            color: "#fff", // Custom text color for the tooltip
          },
          extraCssText:
            "box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);", // Custom CSS for the tooltip
        },
        legend: {
          orient: "vertical",
          left: "left",
          top: "center", // Align to the center vertically
          textStyle: {
            color: "white",
            fontSize: 20,
            rich: {
              name: {
                fontSize: 14, // You can also set specific font sizes for name
                color: "#A0AEC0",
                width: 60, // Adjust the width to control spacing between the name and value
              },
              value: {
                align: "right",
                fontWeight: "bold", // Make the proportion bold
                fontSize: 14, // Ensure the font size is increased for the proportion
                padding: [0, 0, 0, 20], // Adjust padding to control spacing from the legend icon
              },
            },
          },
          formatter: (name) => {
            const item = chartData.find((dataItem) => dataItem.name === name);
            if (item) {
              // If the name is "101" or any other identifier for values over 100, display ">100"
              const displayName = item.name === "101" ? ">100" : item.name;
              return `{name|${displayName}}{value| ${item.proportion}}`;
            }
            return name;
          },
          itemGap: 20, // Adjust the vertical gap between items
          itemWidth: 10, // Width of the legend icon
          itemHeight: 10, // Height of the legend icon
        },
        icon: "circle", // Use circle icons for the legend items
        itemWidth: 10, // Width of the legend icon, you can adjust as needed
        itemHeight: 10, // Height of the legend icon, you can adjust as needed
        color: [
          "#4da6ff", // Light blue
          "#3794ff", // Medium light blue
          "#2d82ff", // Medium blue
          "#1f6fe2", // Medium dark blue
          "#0056b3", // Dark blue
          "#004c99", // Deeper dark blue
        ],
        series: [
          {
            name: "Holding Amount Distribution",
            type: "pie",
            radius: ["50%", "70%"], // Adjust the inner and outer radius to create a donut shape
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            emphasis: {
              scale: true,
              scaleSize: 5,
              label: {
                show: true,
                color: "white",
                fontSize: "18",
                fontWeight: "bold",
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
        ],
      });
    };

    updateOption();
  }, [chartData, totalHolders]);
  useEffect(() => {
    const handleResize = () => {
      const chartContainer = document.getElementById("chart-container");
      const chartWidth = chartContainer.clientWidth;
      const isSmallScreen = chartWidth < 600;
      if (chartWidth < 600) {
        setOption((prevOption) => ({
          ...prevOption,
          graphic: {
            ...prevOption.graphic,
            style: {
              ...prevOption.graphic.style,
              text: isSmallScreen
                ? [
                    "{total|" + totalHolders + "}", // Smaller text for small screens
                    "{holders|Holders}",
                  ].join("\n")
                : [
                    "{total|" + totalHolders + "}", // Larger text for large screens
                    "{holders|Holders}",
                  ].join("\n"),
              rich: {
                ...prevOption.graphic.style.rich,
                total: {
                  ...prevOption.graphic.style.rich.total,
                  fontSize: isSmallScreen ? 20 : 24, // Smaller font size for small screens
                },
                holders: {
                  ...prevOption.graphic.style.rich.holders,
                  fontSize: isSmallScreen ? 12 : 16, // Smaller font size for small screens
                },
              },
            },
          },
          legend: {
            ...prevOption.legend,
            orient: isSmallScreen ? "horizontal" : "vertical",
            left: isSmallScreen ? "center" : "left",
            top: isSmallScreen ? "bottom" : "center",
            textStyle: {
              ...prevOption.legend.textStyle,
              fontSize: isSmallScreen ? 14 : 20, // Smaller font size for small screens
            },
          },
        }));
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call initially to set the correct legend position and orientation

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <Card className="h-100" id="chart-container">
      {" "}
      {/* Ensure your Card has an id */}
      <VuiBox mb="16px">
        <VuiTypography variant="lg" fontWeight="bold" mb="5px" color="white">
          Holding Amount Distribution
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <ReactECharts
          option={option}
          style={{ height: "500px" }}
          opts={{ renderer: "canvas", responsive: true }} // Add responsive option
        />
      </VuiBox>
    </Card>
  );
}

export default ChartPie;
