// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box"; // Import Box from MUI

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// Dashboard layout components
import TopHolders from "layouts/dashboard/components/TopHolders";
import ChartPie from "layouts/dashboard/components/ChartPie";
import HoldingPeriodDistribution from "layouts/dashboard/components/HoldingPeriodDistribution";
import TierTracking from "layouts/dashboard/components/TierTracking";
import XAI from "./components/XAI";
import TokenesXAI from "./components/TokenesXAI";
import ChallengeAssertionReward from "./components/ChallengeAssertionReward";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoBarChart } from "react-icons/io5";
import { IoGitNetworkSharp } from "react-icons/io5";
import { IoHandRight } from "react-icons/io5";

// Web3
import { useState, useEffect } from "react";
import axios from "axios";
import XaiAnalytics from "./components/XaiAnalytics";

function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [totalSupply, setTotalSupply] = useState("Loading...");
  const [numberOfHolders, setNumberOfHolders] = useState("Loading...");
  const [xaiPrice, setXaiPrice] = useState(null);
  const [marketCap, setMarketCap] = useState(0);
  const [priceChangePercentage24h, setPriceChangePercentage24h] = useState(0);
  const [marketCapChangePercentage24h, setMarketCapChangePercentage24h] = useState(0);

  // Modify the fetchXAIPrice function to fetch data from /api/xaiSummary
  async function fetchXAIPrice() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/xaiSummary`);
      if (response.data) {
        const { price, marketCap, priceChangePercentage24h, marketCapChangePercentage24h } =
          response.data;
        setXaiPrice(price);
        setMarketCap(marketCap);
        setPriceChangePercentage24h(priceChangePercentage24h);
        setMarketCapChangePercentage24h(marketCapChangePercentage24h);
      } else {
        console.error("Unexpected response data structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching XAI summary:", error);
    }
  }

  // Update useEffect hook that calls fetchXAIPrice to reflect the new data fetching logic
  useEffect(() => {
    // Fetch the initial data
    fetchXAIPrice();

    // Fetch the data every minute
    const xaiInterval = setInterval(fetchXAIPrice, 60000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(xaiInterval);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [totalSupplyRes, numberOfHoldersRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/totalSupplyNode`),
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/numberOfHolders`),
        ]);

        setTotalSupply(totalSupplyRes.data.totalSupply);
        setNumberOfHolders(numberOfHoldersRes.data.numberOfHolders);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error for both states if needed
        setTotalSupply("Error");
        setNumberOfHolders("Error");
      }
    };

    fetchData();
  }, []);

  // Format the marketCap with commas and two decimal places
  let formattedMarketCap = marketCap.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedTotalSupply = parseInt(totalSupply).toLocaleString();
  const formattedNumberOfHolders = parseInt(numberOfHolders).toLocaleString();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Nodes Sold", fontWeight: "regular" }}
                count={`${formattedTotalSupply} / 50,000`}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: <IoGitNetworkSharp size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "node holders" }}
                count={formattedNumberOfHolders}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: <IoHandRight size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "XAI Price" }}
                infoContent={
                  <>
                    How is the price of Xai (XAI) calculated?
                    <br />
                    <br />
                    The current value of Xai (XAI) is determined instantly by consolidating the most
                    recent information from exchanges and markets, employing a formula that
                    considers the global volume and weighting averages.
                  </>
                }
                count={xaiPrice ? `$${xaiPrice}` : "Loading..."}
                percentage={{
                  color: priceChangePercentage24h >= 0 ? "success" : "error",
                  text: `${
                    priceChangePercentage24h >= 0 ? "+" : ""
                  }${priceChangePercentage24h.toFixed(1)}\u2009%`,
                }}
                icon={{ color: "info", component: <IoIosRocket size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "XAI Marketcap" }}
                infoContent={
                  <>
                    Market Cap = Current Price x Circulating Supply
                    <br />
                    <br />
                    Market Cap, denoted as the product of the current price and circulating supply,
                    represents the collective market worth of a cryptocurrency's circulating tokens.
                    Analogous to how the stock market assesses value by multiplying the price per
                    share with the publicly available shares (excluding those held by insiders or
                    governments), it provides insight into the overall valuation of a
                    cryptocurrency.
                  </>
                }
                count={`$${formattedMarketCap}`}
                percentage={{
                  color: marketCapChangePercentage24h >= 0 ? "success" : "error",
                  text: `${
                    marketCapChangePercentage24h >= 0 ? "+" : ""
                  }${marketCapChangePercentage24h.toFixed(1)}\u2009%`,
                }}
                icon={{ color: "info", component: <IoBarChart size="20px" color="white" /> }}
              />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={6} xl={4}>
              <TierTracking />
            </Grid>

            <Grid item xs={12} lg={6} xl={4}>
              <XAI />
            </Grid>

            <Grid item xs={12} lg={6} xl={4}>
              <TokenesXAI />
            </Grid>

            {/*
  <Grid item xs={12} md={6} lg={6}>
    <XaiAnalytics />
  </Grid>

  <Grid item xs={12} md={6} lg={6}>
    <ChallengeAssertionReward />
  </Grid>
  <Grid item xs={12} md={6} lg={3}>
  */}

            <Grid item xs={12} md={6} lg={3}></Grid>
          </Grid>
        </VuiBox>
        <Grid container spacing={3} direction="row" justifyContent="start" alignItems="stretch">
          <Grid item xs={12} md={6} lg={7}>
            <TopHolders />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Box mb={2}>
              {" "}
              {/* Add bottom margin */}
              <ChartPie />
            </Box>
            <HoldingPeriodDistribution />
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
