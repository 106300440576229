// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <VuiBox
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      direction="row"
      component="footer"
      py={2}
      pb={0}
    >
      <VuiBox xs={12} sx={{ textAlign: "center" }}>
        <VuiTypography
          variant="button"
          sx={{
            textAlign: "center",
            fontWeight: "400 !important",
            "& a": {
              // Add this block for styling the link
              color: "inherit",
              textDecoration: "none", // No underline by default
              "&:hover": {
                textDecoration: 'underline !important', // Underline on hover
              },
            },
          }}
          color="white"
        >
          Made by{" "}
          <a href="https://twitter.com/happybutsad_ai" target="_blank" rel="noopener noreferrer">
            happybutsad :):
          </a>{" "}
        </VuiTypography>
      </VuiBox>
      {/*
      <VuiBox xs={10}>
        <VuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
          <VuiBox mr={{ xs: "20px", lg: "46px" }}>
            <VuiTypography component="a" href="#" variant="body2" color="white">
              Xaico
            </VuiTypography>
          </VuiBox>
          <VuiBox mr={{ xs: "20px", lg: "46px" }}>
            <VuiTypography component="a" href="#" variant="body2" color="white">
              Xaico
            </VuiTypography>
          </VuiBox>
          <VuiBox>
            <VuiTypography component="a" href="#" variant="body2" color="white">
              Xaico
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      */}
    </VuiBox>
  );
}

export default Footer;
