import React from 'react';
import PropTypes from 'prop-types';
// Import your PNG image
import logoPng from './Icon.png';

function SimmmpleLogo({ size }) {
  // Ensure size is applied correctly, assuming size is like "16px" or "32px"
  const logoStyle = {
    width: size,
    height: size
  };

  return (
    <img
      src={logoPng}
      alt="Simmmple Logo"
      style={logoStyle}
    />
  );
}

// Setting default values for the props of SimmmpleLogo
SimmmpleLogo.defaultProps = {
  size: "16px",
};

// Typechecking props for the SimmmpleLogo
SimmmpleLogo.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SimmmpleLogo;