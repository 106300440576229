import React, { useEffect, useState } from "react";
import { Card, Stack } from "@mui/material";
import balance from "assets/images/billing-background-balance.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import axios from "axios";
import { Tooltip } from "@mui/material";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

const XAI = () => {
  const { info, gradients } = colors;
  const { cardContent } = gradients;
  const [circulatingSupply, setCirculatingSupply] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [maxSupply, setMaxSupply] = useState("");
  const [fullyDilutedValuation, setFullyDilutedValuation] = useState("");

  useEffect(() => {
    async function fetchXAI() {
      try {
        const summaryResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/xaiSummary`
        );
        const totalSupplyResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/totalsupplyXAI`
        );
        if (summaryResponse.data && totalSupplyResponse.data) {
          const { circulatingSupply, maxSupply, fullyDilutedValuation } = summaryResponse.data;
          const { totalSupplyXAI } = totalSupplyResponse.data; // Adjusted to match the response format
          setCirculatingSupply(circulatingSupply);
          setMaxSupply(maxSupply);
          setFullyDilutedValuation(fullyDilutedValuation);
          setTotalSupply(totalSupplyXAI); // Set the totalSupply state with the correct value
        } else {
          console.error(
            "Unexpected response data structure:",
            summaryResponse.data,
            totalSupplyResponse.data
          );
        }
      } catch (error) {
        console.error("Error fetching XAI summary or total supply:", error);
      }
    }
    fetchXAI();
  }, []);

  function formatNumber(number) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  }

  return (
    <Card sx={{ padding: "30px", height: "100%", gap: "16px" }}>
      <VuiTypography variant="lg" color="white" mr="auto" fontWeight="bold">
        XAI
      </VuiTypography>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Fully Diluted Valuation" }}
          infoContent={
            <>
              Fully Diluted Valuation (FDV) = Current Price x Total Supply
              <br />
              <br />
              The Fully Diluted Valuation (FDV) represents the hypothetical market capitalization of
              a cryptocurrency when its entire supply is actively circulating, determined by its
              present market price. However, this value remains theoretical due to potential
              fluctuations in market price resulting from changes in supply circulation.
              Additionally, factors such as tokenomics, emission schedules, or lock-up periods can
              influence the time it takes for the complete supply of a cryptocurrency to enter
              circulation.
            </>
          }
          count={`$${formatNumber(fullyDilutedValuation)}`}
          icon={{}}
        />
      </VuiBox>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Circulating Supply" }}
          infoContent={
            <>
              The quantity of coins in circulation within the market and available for public
              trading. This can be likened to examining shares that are readily accessible for
              trading on the market, excluding those held or locked by insiders or governments.
            </>
          }
          count={formatNumber(circulatingSupply)}
          icon={{}}
        />
      </VuiBox>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Total Supply" }}
          infoContent={
            <>
              The total supply refers to the quantity of coins that have been generated thus far,
              subtracting any coins that have been eliminated from circulation (burned). This
              concept is akin to outstanding shares in the stock market.
              <br />
              <br />
              Total Supply = Coins created - Tokens removed from circulation
            </>
          }
          count={formatNumber(totalSupply)}
          icon={{}}
        />
      </VuiBox>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Max Supply" }}
          infoContent={
            <>
              The total number of coins programmed to be in circulation throughout the lifespan of
              the digital currency. This is akin to the maximum number of shares that can be issued
              in the stock market.
              <br />
              <br />
              The combined total of XAI and esXAI will never exceed 2,500,000,000. Given the Xai
              ecosystem is dynamic, accurately predicting monthly token reward per Sentry Key is not
              possible.
            </>
          }
          count={formatNumber(maxSupply)}
          icon={{}}
        />
      </VuiBox>
    </Card>
  );
};

export default XAI;
