// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ReactECharts from "echarts-for-react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function HoldingPeriodDistribution() {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [total, setTotal] = useState(0); // State to store the total
  const [option, setOption] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/holdingPeriod`);
        const responseData = response.data.data;
        const distributionData = responseData.distribution;
        const labels = distributionData.map((item) => item.name);
        const values = distributionData.map((item) => ({
          name: item.name,
          value: item.value,
          proportion: item.proportion,
        }));

        setChartData(values);
        setTotal(responseData.total); // Set the total from the response

        // You don't need to set chart options here if they are static and set in getOption
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getOption = () => ({
      graphic: {
        type: "text",
        left: "center", // Position horizontally in the middle
        top: "center", // Position vertically in the middle
        style: {
          text: [
            "{total|" + (total / 1000).toFixed(1) + "K}", // Use the 'total' style and format the number
            "{items|Items}", // Use the 'items' style
          ].join("\n"), // Join with a newline character
          rich: {
            total: {
              // Style for the 'total' part
              textAlign: "center",
              fill: "#fff", // Text color for totalHolders
              fontSize: 24,
              fontWeight: "bold",
              lineHeight: 40, // Adjust line height for better spacing
            },
            items: {
              // Style for the 'holders' part
              textAlign: "center",
              fill: "#A0AEC0", // Text color for "Holders"
              fontSize: 16, // Smaller font size for "Holders"
              fontWeight: "normal", // Non-bold for "Holders"
            },
          },
          textAlign: "center",
          verticalAlign: "middle",
        },
      },
      tooltip: {
        trigger: "item",
        // Custom formatter for tooltip
        formatter: (params) => {
          // Find the data item by name to get its proportion
          const item = chartData.find((dataItem) => dataItem.name === params.name);
          return `${params.name}<br/>${item ? item.proportion : ""}`;
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Custom background color for the tooltip
        borderColor: "#333", // Custom border color for the tooltip
        borderWidth: 0,
        textStyle: {
          color: "#fff", // Custom text color for the tooltip
        },
        extraCssText:
          "box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);", // Custom CSS for the tooltip
      },
      legend: {
        orient: "vertical",
        left: "left",
        top: "center", // Align to the center vertically
        textStyle: {
          color: "white",
          fontSize: 20,
          rich: {
            name: {
              fontSize: 14, // You can also set specific font sizes for name
              color: "#A0AEC0",
              width: 60, // Adjust the width to control spacing between the name and value
            },
            value: {
              align: "right",
              fontWeight: "bold", // Make the proportion bold
              fontSize: 14, // Ensure the font size is increased for the proportion
              padding: [0, 0, 0, 20], // Adjust padding to control spacing from the legend icon
            },
          },
        },
        formatter: (name) => {
          const item = chartData.find((dataItem) => dataItem.name === name);
          if (item) {
            // Ensure that the value is a percentage and does not need further conversion
            return `{name|${name}}{value| ${item.proportion}}`;
          }
          return name;
        },
        itemGap: 20, // Adjust the vertical gap between items
        itemWidth: 10, // Width of the legend icon
        itemHeight: 10, // Height of the legend icon
      },
      icon: "circle", // Use circle icons for the legend items
      itemWidth: 10, // Width of the legend icon, you can adjust as needed
      itemHeight: 10, // Height of the legend icon, you can adjust as needed
      color: [
        "#4da6ff", // Light blue
        "#3794ff", // Medium light blue
        "#2d82ff", // Medium blue
        "#1f6fe2", // Medium dark blue
        "#0056b3", // Dark blue
        "#004c99", // Deeper dark blue
      ],
      series: [
        {
          name: "Holding Amount Distribution",
          type: "pie",
          radius: ["50%", "70%"], // Adjust the inner and outer radius to create a donut shape
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          emphasis: {
            scale: true,
            scaleSize: 5,
            label: {
              show: true,
              color: "white",
              fontSize: "18",
              fontWeight: "bold",
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          labelLine: {
            show: false,
          },
          data: chartData,
        },
      ],
    });

    setOption(getOption());
  }, [chartData, total]);

  useEffect(() => {
    const handleResize = () => {
      const chartContainer = document.getElementById("holding-period-chart-container");
      const chartWidth = chartContainer.clientWidth;
      const isSmallScreen = chartWidth < 600;

      setOption((prevOption) => ({
        ...prevOption,
        // Update other chart options based on new size if necessary
        legend: {
          ...prevOption.legend,
          orient: isSmallScreen ? "horizontal" : "vertical",
          left: isSmallScreen ? "center" : "left",
          top: isSmallScreen ? "bottom" : "center",
          textStyle: {
            ...prevOption.legend.textStyle,
            fontSize: isSmallScreen ? 14 : 20,
          },
        },
        // Add any other responsive adjustments here
      }));
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call initially to set the correct legend position and orientation

    return () => window.removeEventListener("resize", handleResize);
  }, [total]); // Depend on total to update options

  return (
    <Card className="h-100" id="holding-period-chart-container">
      <VuiBox mb="16px">
        <div style={{ display: "flex", alignItems: "center" }}>
          <VuiTypography variant="lg" fontWeight="bold" mb="5px" color="white">
            Holding Period Distribution
          </VuiTypography>
          <Tooltip
            title="Holding Period Distribution refers to the distribution of the time duration of nodes that are held"
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  bgcolor: "secondary.main",
                  color: "white",
                  maxWidth: 380,
                  padding: "16px",
                  textAlign: "left",
                  fontSize: "0.875rem",
                },
              },
            }}
          >
            <InfoIcon style={{ marginLeft: "5px", marginBottom: "3px", color: "white" }} />
          </Tooltip>
        </div>
      </VuiBox>
      <VuiBox>
        <ReactECharts
          option={option}
          style={{ height: "500px" }}
          opts={{ renderer: "canvas", responsive: true }}
        />
      </VuiBox>
    </Card>
  );
}

export default HoldingPeriodDistribution;
