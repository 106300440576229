// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";


import axios from "axios";
import { useEffect, useState } from "react";

export default function data() {
  const [holdersData, setHoldersData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Update the URL to point to your server's endpoint
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/topHolders`);
        setHoldersData(response.data); // Assuming your server endpoint returns the data directly
      } catch (error) {
        console.error("Failed to fetch top holders from server:", error);
        // Handle error appropriately
      }
    };

    fetchData();
  }, []);

  // Initial state
  if (!holdersData) {
    return {
      columns: [
        { name: "rank", align: "left" },
        { name: "address", align: "left" },
        { name: "quantity", align: "center" },
        { name: "percentage", align: "center" },
      ],
      rows: [],
    };
  }

  // State after data is fetched
  // Only include the first 20 holders
  const first20Holders = holdersData.slice(0, 100);
  return {
    columns: [
      { name: "rank", align: "left" },
      { name: "address", align: "left" },
      { name: "quantity", align: "center" },
      { name: "percentage", align: "center" },
    ],
    rows: first20Holders.map((holder, index) => ({
      rank: (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            {index + 1}
          </VuiTypography>
        </VuiBox>
      ),
      address: (
        <a
          href={`https://arbiscan.io/token/0xbc14d8563b248b79689ecbc43bba53290e0b6b66?a=${holder.address}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          onMouseEnter={(e) => {
            e.currentTarget.style.setProperty('text-decoration', 'underline', 'important')
            e.currentTarget.style.setProperty('text-decoration-color', 'white', 'important')
            e.currentTarget.style.setProperty('text-decoration-thickness', '2px', 'important')
            
          }}
          onMouseLeave={(e) => e.currentTarget.style.setProperty('text-decoration', 'none', 'important')}
        >
          {" "}
          <VuiTypography variant="button" color="white" fontWeight="bold">
            {holder.address}
          </VuiTypography>
        </a>
      ),
      quantity: (
        <VuiTypography variant="button" color="white" fontWeight="bold">
          {holder.value}
        </VuiTypography>
      ),
      percentage: (
        <VuiBox width="8rem" textAlign="center">
          <VuiTypography color="white" variant="button" fontWeight="bold">
            {holder.proportion}
          </VuiTypography>
          <VuiProgress
            value={parseFloat(holder.proportion)}
            color="info"
            label={false}
            sx={{ background: "#2D2E5F" }}
          />
        </VuiBox>
      ),
    })),
  };
}
