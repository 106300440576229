import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";
import Button from "@mui/material/Button"; // Import Button for pagination controls

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard Material-UI example components
import Table from "examples/Tables/Table";

// Data
import data from "layouts/dashboard/components/TopHolders/data";

function TopHolders() {
  const [currentPage, setCurrentPage] = useState(1);
  const holdersPerPage = 20;

  // Assuming `data` is an array of your top holders
  const { columns, rows: allHolders } = data();

  // Calculate the index of the first and last holder on the current page
  const indexOfLastHolder = currentPage * holdersPerPage;
  const indexOfFirstHolder = indexOfLastHolder - holdersPerPage;

  // Slice the data for the current page
  const currentHolders = allHolders.slice(indexOfFirstHolder, indexOfLastHolder);

  // Change page handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(allHolders.length / holdersPerPage);

  return (
    <Card sx={{ height: "100% !important" }}>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
          Sentry Node - Top 100 Holders
        </VuiTypography>
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={currentHolders} />
      </VuiBox>
      <VuiBox display="flex" justifyContent="center" mt={2}>
        {[...Array(totalPages).keys()].map((number) => (
          <Button
            key={number + 1}
            onClick={() => paginate(number + 1)}
            sx={{ margin: "0 5px" }}
            variant={currentPage === number + 1 ? "contained" : "outlined"}
            color="primary"
            size="small" // You can change this to 'medium' or 'large'
          >
            {number + 1}
          </Button>
        ))}
      </VuiBox>
    </Card>
  );
}

export default TopHolders;