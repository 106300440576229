import React from "react";
import ReactApexChart from "react-apexcharts";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.lineChartData || [], // Fallback to empty array if undefined
      chartOptions: props.lineChartOptions || {}, // Fallback to empty object if undefined
    };
  }

  componentDidMount() {
    // If the props are updated later, you can handle it here
    // This will also cover the cases where the props might not be available on initial mount
    this.setState({
      chartData: this.props.lineChartData || [],
      chartOptions: this.props.lineChartOptions || {},
    });
  }

  render() {
    const { chartData, chartOptions } = this.state;

    // Check if the data is available before rendering the chart
    if (!chartData || chartData.length === 0) {
      return <div>Loading chart data...</div>; // Or any other placeholder
    }

    return (
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="area"
        width="100%"
        height="100%"
      />
    );
  }
}

export default LineChart;