import React from "react";
import { Card, Stack } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import { FaEllipsisH } from "react-icons/fa";
import linearGradient from "assets/theme/functions/linearGradient";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import VuiProgress from "components/VuiProgress";

function TierTracking() {
  const { info, gradients } = colors;
  const { cardContent } = gradients;
  const [ethereumPrice, setEthereumPrice] = useState(0);
  const [totalSupply, setTotalSupply] = useState("Loading...");

  useEffect(() => {
    const fetchTotalSupply = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/totalSupplyNode`
        );
        setTotalSupply(response.data.totalSupply);
      } catch (error) {
        console.error("Error fetching total supply:", error);
        setTotalSupply("Error");
      }
    };

    fetchTotalSupply();
  }, []);

  async function fetchEthereumPrice() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/ethereumPrice`);

      // Check if the necessary data exists before trying to access it
      if (response.data && response.data.ethereumPrice) {
        setEthereumPrice(response.data.ethereumPrice);
      } else {
        console.error("Unexpected response data structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching Ethereum price:", error);
    }
  }

  useEffect(() => {
    // Fetch the initial Ethereum price
    fetchEthereumPrice();
    // Fetch the Ethereum price every minute
    const interval = setInterval(fetchEthereumPrice, 60000);
    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const tiers = [
    { tier: 1, quantity: 3000, price: 0.133 },
    { tier: 2, quantity: 2841, price: 0.15295 },
    { tier: 3, quantity: 2690, price: 0.1758925 },
    { tier: 4, quantity: 2547, price: 0.202276375 },
    { tier: 5, quantity: 2412, price: 0.232617831 },
    { tier: 6, quantity: 2284, price: 0.267510506 },
    { tier: 7, quantity: 2163, price: 0.307637082 },
    { tier: 8, quantity: 2048, price: 0.353782644 },
    { tier: 9, quantity: 1939, price: 0.406850041 },
    { tier: 10, quantity: 1836, price: 0.467877547 },
    { tier: 11, quantity: 1738, price: 0.538059179 },
    { tier: 12, quantity: 1645, price: 0.618768056 },
    { tier: 13, quantity: 1557, price: 0.711583264 },
    { tier: 14, quantity: 1474, price: 0.818320754 },
    { tier: 15, quantity: 1395, price: 0.941068867 },
    { tier: 16, quantity: 1321, price: 1.082229197 },
    { tier: 17, quantity: 1251, price: 1.244563576 },
    { tier: 18, quantity: 1184, price: 1.431248113 },
    { tier: 19, quantity: 1121, price: 1.645935329 },
    { tier: 20, quantity: 1061, price: 1.892825629 },
    { tier: 21, quantity: 1004, price: 2.176749473 },
    { tier: 22, quantity: 950, price: 2.503261894 },
    { tier: 23, quantity: 899, price: 2.878751178 },
    { tier: 24, quantity: 851, price: 3.310563855 },
    { tier: 25, quantity: 805, price: 3.807148433 },
    { tier: 26, quantity: 762, price: 4.378220698 },
    { tier: 27, quantity: 721, price: 5.034953803 },
    { tier: 28, quantity: 682, price: 5.790196874 },
    { tier: 29, quantity: 645, price: 6.658726405 },
    { tier: 30, quantity: 610, price: 7.657535365 },
    { tier: 31, quantity: 577, price: 8.80616567 },
    { tier: 32, quantity: 546, price: 10.127090521 },
    { tier: 33, quantity: 517, price: 11.646154099 },
    { tier: 34, quantity: 489, price: 13.393077214 },
    { tier: 35, quantity: 463, price: 15.402038796 },
    { tier: 36, quantity: 438, price: 17.712344615 },
    { tier: 37, quantity: 414, price: 20.369196307 },
    { tier: 38, quantity: 392, price: 23.424575754 },
    { tier: 39, quantity: 371, price: 26.938262117 },
    { tier: 40, quantity: 357, price: 30.979001434 },
  ];

  function getTierAndRemaining(totalSold) {
    let remaining = totalSold;
    let currentTier = null;

    for (let i = 0; i < tiers.length; i++) {
      // If remaining is less than the current tier quantity, we've found our tier
      if (remaining < tiers[i].quantity) {
        currentTier = tiers[i];
        break;
      } else {
        // Subtract the tier's quantity from remaining as we move to the next tier
        remaining -= tiers[i].quantity;
      }
    }

    // If we've gone through all tiers and haven't found a current tier, we're past the last tier
    if (!currentTier) {
      currentTier = tiers[tiers.length - 1];
      remaining = 0; // Since we're past the last tier, there's no remaining until the next tier
    }

    return {
      currentTier: currentTier.tier,
      remainingUntilNextTier: currentTier.quantity - remaining,
      price: currentTier.price,
    };
  }

  // Usage
  const totalSold = Number.isFinite(parseInt(totalSupply)) ? parseInt(totalSupply) : 0;
  const tierInfo = getTierAndRemaining(totalSold);
  const { currentTier, remainingUntilNextTier, price } = tierInfo;

  // Get total quantity for the current tier
  const totalQuantity = tiers.find((tier) => tier.tier === currentTier).quantity;

  // Calculate progress percentage only if remainingUntilNextTier is a number
  const progressPercentage = Number.isNaN(remainingUntilNextTier)
    ? 0
    : ((totalQuantity - remainingUntilNextTier) / totalQuantity) * 100;

  // Calculate the remaining percentage until the next tier
  const remainingPercentageUntilNextTier = 100 - progressPercentage;

  // Convert ETH to USD
  const priceInUSD = (price * ethereumPrice).toFixed(2);

  function getNextTierPrice(currentTier) {
    // Find the index of the current tier in the tiers array
    const currentTierIndex = tiers.findIndex((tier) => tier.tier === currentTier);

    // Check if the current tier is not the last one
    if (currentTierIndex !== -1 && currentTierIndex < tiers.length - 1) {
      // Return the price of the next tier
      return tiers[currentTierIndex + 1].price;
    } else {
      // If the current tier is the last one, there is no next tier
      // Handle this case as per your application's logic
      return null;
    }
  }

  // Usage
  const nextTierPrice = getNextTierPrice(currentTier);

  // Convert ETH to USD for next tier
  const priceInUSDNextTier = (nextTierPrice * ethereumPrice).toFixed(2);

  return (
    <Card
      sx={{
        height: "100%",
        background: linearGradient(
          gradients.cardDark.main,
          gradients.cardDark.state,
          gradients.cardDark.deg
        ),
      }}
    >
      <VuiBox sx={{ width: "100%" }}>
        <VuiBox
          display="flex"
          alignItems="center"
          justifyContent="space-beetween"
          sx={{ width: "100%" }}
          mb="40px"
        >
          <VuiTypography variant="lg" color="white" mb="5px" fontWeight="bold">
            Tier Tracking
          </VuiTypography>
        </VuiBox>
        <VuiBox
          display="flex"
          sx={({ breakpoints }) => ({
            [breakpoints.up("xs")]: {
              flexDirection: "column",
              gap: "16px",
              justifyContent: "center",
              alignItems: "center",
            },
            [breakpoints.up("md")]: {
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            },
          })}
        >
          <Stack
            direction="column"
            spacing="20px"
            width="500px"
            maxWidth="50%"
            sx={({ breakpoints }) => ({
              mr: "auto",
              [breakpoints.only("md")]: {
                mr: "75px",
              },
              [breakpoints.only("xl")]: {
                width: "500px",
                maxWidth: "40%",
              },
            })}
          >
            <VuiBox
              display="flex"
              width="220px"
              p="20px 22px"
              flexDirection="column"
              sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: "20px",
                [breakpoints.up("xl")]: {
                  maxWidth: "110px !important",
                },
                [breakpoints.up("xxl")]: {
                  minWidth: "180px",
                  maxWidth: "100% !important",
                },
              })}
            >
              <VuiTypography color="text" variant="caption" fontWeight="medium" mb="5px">
                Current Tier:
              </VuiTypography>
              <VuiTypography color="white" variant="lg" fontWeight="bold">
                {`${currentTier} / 40`}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              display="flex"
              width="220px"
              p="20px 22px"
              flexDirection="column"
              sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: "20px",
                [breakpoints.up("xl")]: {
                  maxWidth: "110px !important",
                },
                [breakpoints.up("xxl")]: {
                  minWidth: "180px",
                  maxWidth: "100% !important",
                },
              })}
            >
              <VuiTypography color="text" variant="caption" fontWeight="medium" mb="5px">
                Current Tier Price
              </VuiTypography>
              <VuiTypography color="white" variant="md" fontWeight="bold">
                {`Ξ ${price.toFixed(4)}`}
              </VuiTypography>
              <VuiTypography color="white" variant="md" fontWeight="bold">
                {`$ ${priceInUSD}`}
              </VuiTypography>
            </VuiBox>

            <VuiBox
              display="flex"
              width="220px"
              p="20px 22px"
              flexDirection="column"
              sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: "20px",
                [breakpoints.up("xl")]: {
                  maxWidth: "110px !important",
                },
                [breakpoints.up("xxl")]: {
                  minWidth: "180px",
                  maxWidth: "100% !important",
                },
              })}
            >
              <VuiTypography color="text" variant="caption" fontWeight="regular" mb="5px">
                Next Tier Price
              </VuiTypography>
              <VuiTypography color="white" variant="md" fontWeight="bold">
                {nextTierPrice !== null ? `Ξ ${nextTierPrice.toFixed(4)}` : "Last Tier"}
              </VuiTypography>
              <VuiTypography color="white" variant="md" fontWeight="bold">
                {nextTierPrice !== null ? `$ ${priceInUSDNextTier}` : "Last Tier"}
              </VuiTypography>
            </VuiBox>
          </Stack>

          <VuiBox
            sx={{
              display: "flex",
              justifyContent: "center", // Center horizontally in the flex container
              alignItems: "center", // Center vertically in the flex container
              height: "100%", // Take full height of the parent container
              position: "relative", // For absolute positioning of children
              width: "100%", // Ensure the container takes full width
            }}
          >
            <CircularProgress
              variant="determinate"
              value={progressPercentage}
              size={
                window.innerWidth >= 2048
                  ? 250
                  : window.innerWidth >= 1024
                  ? 200
                  : window.innerWidth >= 768
                  ? 200
                  : 210
              }
              color="success"
            />
            <VuiBox
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <VuiBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <VuiTypography color="text" variant="button" mb="4px">
                  Remaining:
                </VuiTypography>
                <VuiTypography
                  color="white"
                  variant="d5"
                  fontWeight="bold"
                  mb="4px"
                  sx={({ breakpoints }) => ({
                    [breakpoints.only("xl")]: {
                      fontSize: "32px",
                    },
                  })}
                >
                  {remainingUntilNextTier}
                </VuiTypography>
                <VuiTypography color="text" variant="button">
                  until next tier
                  <VuiProgress
                    value={progressPercentage}
                    color="info"
                    sx={{ background: "#2D2E5F" }}
                  />
                </VuiTypography>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default TierTracking;
