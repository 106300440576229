import React, { useEffect, useState } from "react";
import { Card, Stack } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import axios from "axios";
import colors from "assets/theme/base/colors";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Function to format percentages
function formatPercentage(number) {
  return `${number.toFixed(2)}%`; // Formats the number to two decimal places and adds a % sign
}

const TokenesXAI = () => {
  const { gradients } = colors;
  const [totalSupplyesXAI, setTotalSupplyesXAI] = useState("");
  const [balanceOfesXAI, setBalanceOfesXAI] = useState("");

  const percentageOfesXAIStaked =
    totalSupplyesXAI > 0 ? (balanceOfesXAI / totalSupplyesXAI) * 100 : 0;

  useEffect(() => {
    async function fetchData() {
      try {
        const totalSupplyResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/totalSupplyesXAI`
        );
        const balanceOfesXAIResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/balanceOfesXAI`
        );
        if (totalSupplyResponse.data) {
          const { totalSupplyesXAI } = totalSupplyResponse.data;
          setTotalSupplyesXAI(totalSupplyesXAI);
        } else {
          console.error("Unexpected response data structure:", totalSupplyResponse.data);
        }
        if (balanceOfesXAIResponse.data) {
          const { balanceOfesXAI } = balanceOfesXAIResponse.data;
          setBalanceOfesXAI(balanceOfesXAI);
        } else {
          console.error("Unexpected response data structure:", balanceOfesXAIResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  function formatNumber(number) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  }

  return (
    <Card sx={{ padding: "30px", height: "100%", gap: "16px" }}>
      <VuiTypography variant="lg" color="white" mr="auto" fontWeight="bold">
        esXAI
      </VuiTypography>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Percentage of Staked Tokens" }}
          infoContent={
            <>
              The current total of esXAI tokens that have been staked is calculated based on the
              total supply of esXAI. This is expressed as a percentage, determined by dividing the
              total staked amount by the total supply and multiplying by 100%.
            </>
          }
          count={formatNumber(percentageOfesXAIStaked) + "%"}
          icon={{}}
        />
      </VuiBox>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Total Staked" }}
          infoContent={
            <>
              The total amount of esXAI tokens that are currently staked in the network. This will
              allow holders to stake their tokens for various benefits.
              <br />
              <br />
            </>
          }
          count={formatNumber(balanceOfesXAI)}
          icon={{}}
        />
      </VuiBox>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Total Supply" }}
          infoContent={
            <>
              The total supply refers to the quantity of coins that have been generated thus far,
              subtracting any coins that have been eliminated from circulation (burned). This
              concept is akin to outstanding shares in the stock market.
              <br />
              <br />
              Total Supply = Coins created - Tokens removed from circulation
            </>
          }
          count={formatNumber(totalSupplyesXAI)}
          icon={{}}
        />
      </VuiBox>

      <VuiBox display="flex" flexDirection="column">
        <MiniStatisticsCard
          title={{ text: "Max Supply" }}
          infoContent={
            <>
              The total number of coins programmed to be in circulation throughout the lifespan of
              the digital currency. This is akin to the maximum number of shares that can be issued
              in the stock market.
              <br />
              <br />
              The combined total of XAI and esXAI will never exceed 2,500,000,000. Given the Xai
              ecosystem is dynamic, accurately predicting monthly token reward per Sentry Key is not
              possible.
            </>
          }
          count={"2,500,000,000"}
          icon={{}}
        />
      </VuiBox>
    </Card>
  );
};

export default TokenesXAI;
